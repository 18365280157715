import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable, Subscription, debounceTime, take } from 'rxjs';
import { SeAnalyticsService } from 'se-analytics';
import { SeFeFormFieldConfig } from 'se-fe-form-field';
import { SeFeScrollService, SeFeScrollToAlignment } from 'se-fe-scroll';
import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast';
import { environment } from '../../../environments/environment';
import { ItemVariationService } from '../../sale-items/services/item-variation.service';
import { Discount } from '../models/discount.model';
import { DiscountFormConfigService } from '../services/discount-form-config.service';
import { DiscountFormService } from '../services/discount-form.service';
import { DiscountSummaryService } from '../services/discount-summary.service';
import { DiscountService } from '../services/discount.service';
import { loadDiscount } from './state/edit-discounts.actions';
import { selectDiscount } from './state/edit-discounts.selectors';
import { CustomerBuysComponent } from '../customer-buys/customer-buys.component';
import { CustomerGetsComponent } from '../customer-gets/customer-gets.component';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';

@Component({
  selector: 'app-edit-discounts-v2',
  templateUrl: './edit-discounts-v2.component.html',
  styleUrls: ['./edit-discounts-v2.component.scss'],
  providers: [DiscountFormConfigService],
})
export class EditDiscountsV2Component implements OnInit, OnDestroy {
  @Input() public discount: Discount;
  @Input() public saving: boolean;
  public subscriptions: Subscription = new Subscription();
  public hasSubmissionErrors = false;
  public submissionErrors: string[];
  availableCodeCount: number;
  codeMaxUsage: string = '';
  discount$: Observable<Discount>;
  discountID: number;
  discountStyle: string = '';
  editDiscountForm: FormGroup;
  initialCodeCount: number;
  suppliedCodeName: string = '';
  usedCodeCount: number;
  isDiscountScopeSubtotal: boolean = false;
  showMaxUsageSection: boolean = false;
  showIndividualCodesSection: boolean = false;
  // What type of discount do you want to create?
  uneditableDiscounts = {
    Automatic: 'Automatic Discount',
    Individual: 'Individual Discount Codes',
    Code: 'Reusable Discount Codes',
  };
  // What do you want to name this discount?
  editDiscountNameConfig: SeFeFormFieldConfig;
  // What is the value of the discount?
  discountValueAmountConfig: SeFeFormFieldConfig;
  discountValueTypeOptionsConfig: SeFeFormFieldConfig;
  discountValueTypeOptions: any;
  discountValueMaxConfig: SeFeFormFieldConfig;
  discountValueMaxButton = false;
  showDiscountValueMaxField = false;
  // How do you want to apply the discount?
  applyDiscountTypeOptionsConfig: SeFeFormFieldConfig;
  applyDiscountTypeOptions: any;
  applyDiscountSaleItemButton = false;
  applyDiscountItemOptionsConfig: SeFeFormFieldConfig;
  applyDiscountItemOptions: any;
  applyDiscountSetAmountField = false;
  applyDiscountSetAmountConfig: SeFeFormFieldConfig;
  // What are the requirements to use this discount?
  discountUseRequirementsOptionsConfig: SeFeFormFieldConfig;
  discountUseRequirementsOptions: any;
  minimumSpendAmountFieldVisible = false;
  minItemsFieldVisible = false;
  minimumSpendAmountFieldConfig: SeFeFormFieldConfig;
  minimumItemsQtyFieldConfig: SeFeFormFieldConfig;
  // How many orders can use this discount?
  discountMaxUseOptionsConfig: SeFeFormFieldConfig;
  discountMaxUseOptions: any;
  discountMaxUseCountFieldConfig: SeFeFormFieldConfig;
  discountMaxUseCountFieldVisible = false;
  // How many individual codes do you need?
  discountIndividualCodesFieldConfig: SeFeFormFieldConfig;
  discountIndividualCodesFieldVisible = false;

  // Buy X Get Y
  customerBuysComponent: CustomerBuysComponent;
  customerGetsComponent: CustomerGetsComponent;

  @ViewChild(CustomerBuysComponent) set setCustomerBuysComponent(customerBuysComponent: CustomerBuysComponent) {
    if (!customerBuysComponent) {
      return;
    }
    this.customerBuysComponent = customerBuysComponent;

    const { required_item_variation_ids, required_items_quantity } = this.discount;

    this.customerBuysComponent.form.patchValue({
      customerBuys: required_item_variation_ids,
      minimumItemCount: required_items_quantity,
    });

    const customerBuysForm = this.customerBuysComponent.form;

    const formMappings = [
      { source: 'customerBuys', target: 'saleItemsRequiredSelections' },
      { source: 'minimumItemCount', target: 'saleItemsPerOrderSelections' },
    ];

    formMappings.forEach((mapping) => {
      this.subscriptions.add(
        customerBuysForm.get(mapping.source).valueChanges.subscribe((value) => {
          this.editDiscountForm.get(mapping.target).setValue(value);
        })
      );
    });
  }

  @ViewChild(CustomerGetsComponent) set setCustomerGetsComponent(customerGetsComponent: CustomerGetsComponent) {
    if (!customerGetsComponent) {
      return;
    }
    this.customerGetsComponent = customerGetsComponent;

    const { discounted_item_variation_ids, discount_type, discount_value, max_eligible_items } =
      this.discount;
    const computedDiscountScope = this.discount.max_eligible_items !== this.discount.discounted_item_variation_ids.length ? 'saleItems' : 'all';
    this.customerGetsComponent.form.patchValue({
      customerGets: discounted_item_variation_ids,
      discountWhat: discount_type,
      discountValue: Number(discount_value),
      discountWhatScope: computedDiscountScope,
      howManyItems: max_eligible_items,
    });

    const customerGetsForm = this.customerGetsComponent.form;

    const formMappings = [
      { source: 'customerGets', target: 'saleItemsPerOrderSelections' },
      { source: 'discountWhat', target: 'discountValueType' },
      { source: 'discountValue', target: 'discountValueAmount' },
      { source: 'discountWhatScope', target: 'applyDiscountTypeRadios' },
      { source: 'howManyItems', target: 'setAmountQtyField' },
    ];

    formMappings.forEach((mapping) => {
      this.subscriptions.add(
        customerGetsForm.get(mapping.source).valueChanges.subscribe((value) => {
          this.editDiscountForm.get(mapping.target).setValue(value);
        })
      );
    });
  }

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: number,
    private fb: FormBuilder,
    private discountFormService: DiscountFormService,
    private discountService: DiscountService,
    private seFeScrollService: SeFeScrollService,
    private toastService: SeFeToastService,
    private router: Router,
    private store: Store,
    private discountFormConfig: DiscountFormConfigService,
    private discountSummaryService: DiscountSummaryService,
    public itemVariationService: ItemVariationService,
    public seAnalyticsService: SeAnalyticsService
  ) {
    this.initializeFormConfig();
    this.store.dispatch(loadDiscount());
    this.discount$ = this.store.select(selectDiscount);
  }

  ngOnInit(): void {
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'Discounts',
      depth3: 'EditDiscount',
    });
    this.createForm();
    this.discountValueTypeChange();
    this.handleApplyDiscountTypeChange();
    this.handleDiscountUseRequirementsChange();
    this.subscribeToFormChanges();

    this.discount$.subscribe((discount) => {
      if (discount) {
        this.discount = discount;
        this.toggleDiscountValueValidators();
        this.bindFormControls(discount);
        this.discountID = discount.id;
        this.discountStyle = discount.discount_style;
        this.codeMaxUsage = discount.code_max_usage;
        this.usedCodeCount = discount.used_code_count;
        this.availableCodeCount = discount.available_count;
        this.initialCodeCount = discount.code_count;
        this.suppliedCodeName = discount.supplied_code;
        this.isDiscountScopeSubtotal = discount.discount_scope === 'Order';
        this.showMaxUsageSection = discount.discount_style === 'Code' || discount.discount_style === 'Automatic';
        this.showIndividualCodesSection = discount.discount_style === 'Individual';
        this.setDiscountValueValidators(discount.discount_type);
      }
    });

    this.setupConditionalValidation();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initializeFormConfig(): void {
    this.editDiscountNameConfig = this.discountFormConfig.editDiscountNameConfig;
    this.discountValueAmountConfig = this.discountFormConfig.discountValueAmountConfig;
    this.discountValueTypeOptionsConfig = this.discountFormConfig.discountValueTypeOptionsConfig;
    this.discountValueTypeOptions = this.discountFormConfig.discountValueTypeOptions;
    this.discountValueMaxConfig = this.discountFormConfig.discountValueMaxConfig;
    this.applyDiscountTypeOptionsConfig = this.discountFormConfig.applyDiscountTypeOptionsConfig;
    this.applyDiscountTypeOptions = this.discountFormConfig.applyDiscountTypeOptions;
    this.discountUseRequirementsOptionsConfig = this.discountFormConfig.discountUseRequirementsOptionsConfig;
    this.discountUseRequirementsOptions = this.discountFormConfig.discountUseRequirementsOptions;
    this.applyDiscountItemOptionsConfig = this.discountFormConfig.applyDiscountItemOptionsConfig;
    this.applyDiscountItemOptions = this.discountFormConfig.applyDiscountItemOptions;
    this.applyDiscountSetAmountConfig = this.discountFormConfig.applyDiscountSetAmountConfig;
    this.minimumSpendAmountFieldConfig = this.discountFormConfig.minimumSpendAmountFieldConfig;
    this.minimumItemsQtyFieldConfig = this.discountFormConfig.minimumItemsQtyFieldConfig;
    this.discountMaxUseOptionsConfig = this.discountFormConfig.discountMaxUseOptionsConfig;
    this.discountMaxUseOptions = this.discountFormConfig.discountMaxUseOptions;
    this.discountMaxUseCountFieldConfig = this.discountFormConfig.discountMaxUseCountFieldConfig;
    this.discountIndividualCodesFieldConfig = this.discountFormConfig.discountIndividualCodesFieldConfig;
  }

  getItemVariationService(): ItemVariationService {
    return this.itemVariationService;
  }

  createForm(): void {
    this.editDiscountForm = this.fb.group(
      {
        // What do you want to name this discount?
        discountName: ['', [Validators.required, Validators.maxLength(64), Validators.minLength(2)]],
        // What is the value of the discount?
        discountValueAmount: [''],
        discountValueType: ['', [Validators.required]],
        discountValueMaxOff: [''],

        // How do you want to apply the discount?
        applyDiscountTypeRadios: ['', [Validators.required]],
        saleItemsPerOrderRadios: [''],
        saleItemsPerOrderSelections: [''],
        setAmountQtyField: [''],

        // What are the requirements to use this discount?
        discountUseRequirementsRadios: ['', [Validators.required]],
        minimumSpendAmountField: [''],
        saleItemsRequiredSelections: [''],
        minimumItemsQtyField: [''],

        // How many orders can use this discount?
        discountMaxUseRadios: ['', [Validators.required]],
        discountMaxUseCount: [''],

        // When will this discount be available?
        fromDate: ['', [Validators.required]],
        toDate: [''],

        // How many individual codes do you need?
        discountIndividualCodeCount: [''],
      },
      {
        validators: this.discountFormConfig.dateRangeValidator('fromDate', 'toDate'),
      }
    );

    this.discountValueTypeChange();
  }

  get formControls() {
    return this.editDiscountForm.controls;
  }

  /*
   * Check nested validations
   * */
  checkFormValidity(): void {
    if (!environment.production) {
      console.log('form valid: ', this.editDiscountForm.valid); // false if the form is invalid
    }
    for (const key of Object.keys(this.editDiscountForm.controls)) {
      const controlErrors = this.editDiscountForm.get(key)?.errors;
      if (controlErrors != null && !environment.production) {
        console.log(`Errors in ${key}:`, controlErrors);
      } else {
        const nestedGroup = this.editDiscountForm.get(key);
        if (nestedGroup instanceof FormGroup) {
          for (const nestedKey of Object.keys(nestedGroup.controls)) {
            const nestedControlErrors = nestedGroup.get(nestedKey)?.errors;
            if (nestedControlErrors != null && !environment.production) {
              console.log(`Errors in ${nestedKey}:`, nestedControlErrors);
            }
          }
        }
      }
    }
  }

  /**
   * Submits the discount form and saves the discount.
   */
  async editDiscountSubmit(): Promise<void> {
    this.checkFormValidity();
    if(this.discount.buyx_gety && !this.customerBuysComponent.minimumItemCountControl.valid) {
      this.customerBuysComponent.minimumItemCountControl.markAsTouched();
      this.customerBuysComponent.minimumItemCountControl.markAsDirty();
      this.customerBuysComponent.customerBuysControl.setValue([]);
    }
    if(this.discount.buyx_gety && !this.customerGetsComponent.customerGetsControl.valid) {
      this.customerGetsComponent.customerGetsControl.setValue([]);
      this.customerGetsComponent.customerGetsControl.markAsTouched();
      this.customerGetsComponent.customerGetsControl.markAsDirty();
      return;
    }
    if (this.editDiscountForm.valid) {
      try {
        this.saving = true;
        this.hasSubmissionErrors = false;
        this.submissionErrors = [];

        const discount = this.mapForm();
        const response = await this.discountService.save(discount);

        if (response.id) {
          const successMessage = 'Successfully Edited';
          this.toastService.addToast({
            type: SeFeToastTypes.Success,
            message: successMessage,
          });
          const goTo = `/discounts/${response.id}/detail`;
          await this.router.navigate([goTo]);
        }
      } catch (e) {
        console.error('Error saving discount', e);
        this.saving = false;
        this.hasSubmissionErrors = true;
        if (e.error && e.error.errors) {
          this.submissionErrors = Object.values(e.error.errors).flat() as string[];
        } else {
          this.submissionErrors = ['Submission Error'];
        }
        const submissionErrorsElement = document.getElementById('submission_errors');
        if (submissionErrorsElement) {
          this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, submissionErrorsElement, {
            offset: 115,
          });
        }
        this.toastService.addToast({
          type: SeFeToastTypes.Error,
          message: 'Error Saving',
        });
      } finally {
        this.saving = false;
      }
    } else {
      this.hasSubmissionErrors = true;
      if (this.editDiscountForm.errors?.dateRangeInvalid) {
        this.submissionErrors = this.discountFormConfig.getDateRangeErrorMessage(
          this.editDiscountForm,
          'fromDate',
          'toDate'
        );
      } else {
        this.submissionErrors = this.discountFormConfig.getFormErrors(this.editDiscountForm);
      }
      this.seFeScrollService.scrollTo(SeFeScrollToAlignment.Top, document.getElementById('submission_errors'), {
        offset: 115,
      });
    }
  }

  /**
   * Updates the validators for the discount value fields based on the visibility of the value card and the type of value.
   *
   * @param {boolean} isValueCardVisible - Indicates whether the value card is visible.
   * @param {string} value - The type of discount value, either 'percentage' or 'amount'.
   *
   */
  updateDiscountValueValidators(isValueCardVisible: boolean, value: string): void {
    const discountValueAmountControl = this.editDiscountForm.get('discountValueAmount');
    const discountValueMaxOffControl = this.editDiscountForm.get('discountValueMaxOff');

    if (isValueCardVisible) {
      if (value === 'percentage') {
        this.discountValueMaxButton = true;
        this.setValidators(discountValueAmountControl, [
          Validators.required,
          Validators.pattern('^(100|\\d{1,2})(\\.\\d{1,2})?$'),
          Validators.min(1),
          Validators.max(100),
          this.discountFormService.percentageDiscountValidator(),
        ]);
      } else if (value === 'amount') {
        this.discountValueMaxButton = false;
        this.showDiscountValueMaxField = false;
        this.setValidators(discountValueMaxOffControl, []);
        discountValueMaxOffControl.setValue('');
        this.setValidators(discountValueAmountControl, [
          Validators.required,
          Validators.pattern('^\\d+(\\.\\d{1,2})?$'),
          Validators.min(1),
          Validators.max(999_999_999.99),
        ]);
      }
    } else {
      this.setValidators(discountValueAmountControl, []);
      discountValueAmountControl.setValue(0.0);
    }
  }

  /**
   * Sets the given validators on the specified form control and updates its validity.
   *
   * @param control - The form control to set validators on.
   * @param validators - An array of validator functions to apply to the control.
   */
  setValidators(control: AbstractControl, validators: ValidatorFn[]): void {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  /**
   * Handles changes to the discount value type.
   * Subscribes to the value changes of the 'discountValueType' form control and updates
   * the discount value validators based on the new value and the visibility of the value card.
   *
   * @remarks
   * This method is triggered when the 'discountValueType' form control value changes.
   * It checks if the value card should be visible based on the `buyx_gety` property of the discount,
   * and then updates the discount value validators accordingly.
   */
  discountValueTypeChange(): void {
    this.editDiscountForm.get('discountValueType').valueChanges.subscribe((value) => {
      const isValueCardVisible = !this.discount?.buyx_gety;
      this.updateDiscountValueValidators(isValueCardVisible, value);
    });
  }

  /**
   * Toggles the validators for the discount value fields based on the current state of the discount.
   *
   * This method checks if the value card should be visible by evaluating the `buyx_gety` property of the discount.
   * It then retrieves the current value of the `discountValueType` form control and updates the discount value validators accordingly.
   *
   */
  toggleDiscountValueValidators(): void {
    const isValueCardVisible = this.discount ? !this.discount.buyx_gety : false;
    const value = this.editDiscountForm.get('discountValueType').value;
    this.updateDiscountValueValidators(isValueCardVisible, value);
  }

  /**
   * Toggles the discountValueMaxField property and clears the discountValueMaxOff form control if the field is toggled off.
   */
  discountValueMaxToggle(): void {
    const discountValueMaxOffControl = this.editDiscountForm.get('discountValueMaxOff');

    this.showDiscountValueMaxField = !this.showDiscountValueMaxField;

    if (this.showDiscountValueMaxField) {
      discountValueMaxOffControl.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        Validators.min(1),
      ]);
      discountValueMaxOffControl.markAsPristine();
      discountValueMaxOffControl.markAsUntouched();
    } else {
      discountValueMaxOffControl.clearValidators();
      discountValueMaxOffControl.setValue(''); // Clear the value if not required
    }

    discountValueMaxOffControl.updateValueAndValidity();
  }

  /**
   * Handles the change event of the applyDiscountTypeRadios form control.
   * Subscribes to the valueChanges event and updates the component's state accordingly.
   * If the selected value is not 'saleItems', it resets the saleItemsPerOrderRadios and applyDiscountSetAmountField.
   * If the selected value is not 'setAmount', it resets the setAmountQtyField.
   */
  handleApplyDiscountTypeChange(): void {
    this.editDiscountForm.get('applyDiscountTypeRadios').valueChanges.subscribe((value) => {
      this.applyDiscountSaleItemButton = value === 'saleItems';
      if (value !== 'saleItems') {
        this.editDiscountForm.get('saleItemsPerOrderRadios').setValue('');
        this.applyDiscountSetAmountField = false;
      }
    });

    this.editDiscountForm.get('saleItemsPerOrderRadios').valueChanges.subscribe((value) => {
      this.applyDiscountSetAmountField = value === 'setAmount';
      if (value !== 'setAmount') {
        this.editDiscountForm.get('setAmountQtyField').setValue('');
      }
    });
  }

  /**
   * Handles the change event of the discount max use radios.
   * Updates the visibility of the discount max use count field based on the selected value.
   * If the discount max use count field is not visible, it sets its value to an empty string.
   */
  discountMaxUseRadiosChange(): void {
    this.editDiscountForm.get('discountMaxUseRadios').valueChanges.subscribe((value) => {
      this.discountMaxUseCountFieldVisible = value === 'limited';
      if (!this.discountMaxUseCountFieldVisible) {
        this.editDiscountForm.get('discountMaxUseCount').setValue('');
      }
    });
  }

  discountMaxUseRedeemed(): string {
    return `${this.usedCodeCount} redeemed`;
  }

  discountMaxUseRemaining(): string {
    const remainingText = this.availableCodeCount - this.usedCodeCount === 1 ? 'more time' : 'more times';
    if (this.availableCodeCount - this.usedCodeCount === 0) {
      return 'No redeemable codes';
    }
    return `Redeemable up to ${this.availableCodeCount - this.usedCodeCount} ${remainingText}`;
  }

  discountCodesAvailable(): string {
    return `${this.initialCodeCount} available`;
  }

  discountMaxUseTotal(): string {
    if (this.initialCodeCount === 1) {
      return '1 individual code has been generated';
    }
    return `${this.initialCodeCount} individual codes have been generated`;
  }

  /**
   * Handles the change event of the discount use requirements radios.
   * Updates the visibility of the minimum spend amount field and the minimum items quantity field based on the selected value.
   * If the minimum spend amount field is not visible, it sets its value to an empty string.
   * If the minimum items quantity field is not visible, it sets its value to an empty string.
   */
  handleDiscountUseRequirementsChange(): void {
    this.editDiscountForm.get('discountUseRequirementsRadios').valueChanges.subscribe((value) => {
      this.minimumSpendAmountFieldVisible = value === 'minimumSpend';
      this.minItemsFieldVisible = value === 'minimumItems';

      if (!this.minimumSpendAmountFieldVisible) {
        this.editDiscountForm.get('minimumSpendAmountField').setValue('');
      }
      if (!this.minItemsFieldVisible) {
        this.editDiscountForm.get('minimumItemsQtyField').setValue('');
      }
    });
  }

  excludeDatesAfterEnd(): { end: string }[] {
    if (!this.editDiscountForm.value.fromDate) {
      return [];
    }
    const today = DateTime.now();
    const startDate = DateTime.fromFormat(
      this.editDiscountForm.value.fromDate,
      this.discountFormService.UI_DATE_FORMAT
    );
    const startBlockDate = today > startDate ? startDate : today;
    return [
      {
        end: startBlockDate.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT),
      },
    ];
  }

  excludeDatesBeforeStart(): { end: string }[] {
    if (!this.editDiscountForm.value.fromDate) {
      return [];
    }
    const startDate = DateTime.fromFormat(
      this.editDiscountForm.value.fromDate,
      this.discountFormService.UI_DATE_FORMAT
    );
    return [
      {
        end: startDate.minus({ day: 1 }).toFormat(this.discountFormService.DB_DATE_FORMAT),
      },
    ];
  }

  bindFormControls(discount: Discount): void {
    this.editDiscountForm.get('discountName').setValue(discount.name);
    this.editDiscountForm.get('discountValueAmount').setValue(this.formatDiscountValueAmount(discount.discount_value));
    this.editDiscountForm.get('discountValueType').setValue(discount.discount_type);
    this.editDiscountForm.get('discountValueMaxOff').setValue(discount.maximum_discount_value);
    this.showDiscountValueMaxField = !!discount.maximum_discount_value;

    if (discount.starts_at) {
      const fromDate = DateTime.fromISO(discount.starts_at).toFormat(this.discountFormService.UI_DATE_FORMAT);
      const fromDateControl = this.editDiscountForm.get('fromDate');
      fromDateControl.setValue(fromDate);
      fromDateControl.markAsPristine();
    }
    if (discount.expires_at) {
      const toDate = DateTime.fromISO(discount.expires_at).toFormat(this.discountFormService.UI_DATE_FORMAT);
      this.editDiscountForm.get('toDate').setValue(toDate);
    }

    this.editDiscountForm
      .get('applyDiscountTypeRadios')
      .setValue(discount.discount_scope === 'Order' ? 'subtotal' : 'saleItems'); // 'Item' or 'Order'?

    this.editDiscountForm.get('saleItemsPerOrderSelections').setValue(discount.discounted_item_variation_ids);

    this.editDiscountForm
      .get('saleItemsPerOrderRadios')
      .setValue(this.getSaleItemsPerOrderValue(discount.max_eligible_items));
    this.editDiscountForm.get('setAmountQtyField').setValue(discount.max_eligible_items || null);

    this.editDiscountForm
      .get('discountUseRequirementsRadios')
      .setValue(
        discount.requirement_type === 'None' || discount.requirement_type === null
          ? 'noRequirements'
          : discount.requirement_type === 'Quantity'
          ? 'minimumItems'
          : 'minimumSpend'
      );

    this.editDiscountForm.get('saleItemsRequiredSelections').setValue(discount.required_item_variation_ids);
    this.editDiscountForm.get('minimumItemsQtyField').setValue(discount.required_items_quantity);
    this.editDiscountForm.get('minimumSpendAmountField').setValue(discount.minimum_sale_total_cents / 100);

    this.editDiscountForm.get('discountMaxUseCount').setValue(discount.max_use_count);
    this.editDiscountForm.get('discountMaxUseRadios').setValue(discount.max_use_count ? 'limited' : 'unlimited');
    this.editDiscountForm.get('discountIndividualCodeCount').setValue('');
  }

  getSaleItemsPerOrderValue(maxEligibleItems: number | null): string | null {
    if (maxEligibleItems === null) {
      return null;
    }
    switch (maxEligibleItems) {
      case 0:
        return 'allItems';
      case 1:
        return 'oneItem';
      default:
        return 'setAmount';
    }
  }

  /**
   * Updates the exclude dates.
   */
  public updateExcludeDates(): void {}

  /**
   * Updates the discounted items in the form.
   */
  updateDiscountedItemsTable(selectedItems: number[]): void {
    if (!Array.isArray(selectedItems)) {
      selectedItems = selectedItems ? [selectedItems] : [];
    }
    const discountedItems = selectedItems.filter((item) => item).map((item) => item);
    this.editDiscountForm.get('saleItemsPerOrderSelections')?.setValue(discountedItems);
    const applyDiscountType = this.editDiscountForm.get('applyDiscountTypeRadios')?.value;
    if (applyDiscountType === 'saleItems' && discountedItems.length === 0) {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors({ required: true });
    } else {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors(null);
    }
    const discountedItemsStrings = discountedItems.map(String);
    this.fetchItemVariations(discountedItemsStrings);
    this.validateSelectedItems();
  }

  private fetchItemVariations(discountedItems: string[]): void {
    if (discountedItems.length > 0) {
      const queryParams = {
        id_list: discountedItems.join(','),
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        if (!environment.production) {
          console.log('Fetch sale items:', response.result);
        }
      });
    }
  }

  private validateSelectedItems(): void {
    const applyDiscountType = this.editDiscountForm.get('applyDiscountTypeRadios')?.value;
    const discountedItems = this.editDiscountForm.get('saleItemsPerOrderSelections')?.value || [];

    if (applyDiscountType === 'saleItems' && discountedItems.length === 0) {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors({
        required: true,
      });
    } else {
      this.editDiscountForm.get('saleItemsPerOrderSelections')?.setErrors(null);
    }
  }

  /**
   * Updates the required items in the form.
   */
  updateRequiredItemsTable(requiredItems: number[]): void {
    if (!Array.isArray(requiredItems)) {
      requiredItems = requiredItems ? [requiredItems] : [];
    }
    const requiredItemIds = requiredItems.filter((item) => item).map((item) => item);
    this.editDiscountForm.get('saleItemsRequiredSelections')?.setValue(requiredItemIds);
    const discountRequirements = this.editDiscountForm.get('discountUseRequirementsRadios')?.value;
    if (discountRequirements === 'minimumItems' && requiredItemIds.length === 0) {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors({ required: true });
    } else {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors(null);
    }
    const requiredItemIdsStrings = requiredItemIds.map(String);
    this.fetchRequiredItemVariations(requiredItemIdsStrings);
    this.validateRequiredItems();
  }

  private fetchRequiredItemVariations(requiredItemIds: string[]): void {
    if (requiredItemIds.length > 0) {
      const queryParams = {
        id_list: requiredItemIds.join(','),
        'order[full_name]': 'asc',
        unpaginated: true,
        include_zero_rate: 1,
      };
      this.itemVariationService.getItemVariationsByID(queryParams).subscribe((response) => {
        if (!environment.production) {
          console.log('Fetched required items:', response.result);
        }
      });
    }
  }

  private validateRequiredItems(): void {
    const discountRequirements = this.editDiscountForm.get('discountUseRequirementsRadios')?.value;
    const requiredItemIds = this.editDiscountForm.get('saleItemsRequiredSelections')?.value || [];

    if (discountRequirements === 'minimumItems' && requiredItemIds.length === 0) {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors({
        required: true,
      });
    } else {
      this.editDiscountForm.get('saleItemsRequiredSelections')?.setErrors(null);
    }
  }

  // How do you want to apply the discount?
  getDiscountScope(): string {
    return this.discountSummaryService.getDiscountScope(this.editDiscountForm);
  }

  // How do you want to apply the discount card, Discount the sale items you choose option
  getSaleItemsPerOrderRadios(): string {
    return this.discountSummaryService.getSaleItemsPerOrderRadios(this.editDiscountForm);
  }

  // How do you want to apply the discount? card, Discount the sale items you choose option
  getSaleItemsText(): string {
    return this.discountSummaryService.getSaleItemsText(this.editDiscountForm);
  }

  //What are the requirements to use this discount? card
  getDiscountUseRequirements(): string {
    return this.discountSummaryService.getDiscountUseRequirements(this.editDiscountForm);
  }

  // What are the requirements to use this discount?
  getDiscountUsage(): string {
    return this.discountSummaryService.getDiscountUsage(this.editDiscountForm);
  }

  // What are the requirements to use this discount? card, eligible sale item selection
  getRequiredItemsText(): string {
    return this.discountSummaryService.getRequiredItemsText(this.editDiscountForm);
  }

  // What are the requirements to use this discount? card, Buy a minimum number (#) of sale items options
  getminimumItemsQtyField(): string {
    return this.discountSummaryService.getminimumItemsQtyField(this.editDiscountForm);
  }

  // When will this discount be available? card
  getDiscountDateRange(): string {
    return this.discountSummaryService.getDiscountDateRange(this.editDiscountForm);
  }

  // What is the value of the discount? card
  getFormattedDiscountValue(): string {
    return this.discountSummaryService.getFormattedDiscountValue(this.editDiscountForm);
  }

  getDiscountStyle(): string {
    const style = this.discountStyle;
    switch (style) {
      case 'Automatic':
        return 'automatic';
      case 'Individual':
        return `individual`;
      case 'Code':
        return `reusable`;
    }
  }

  getMaxUsage(): string {
    if (this.codeMaxUsage == null) {
      return 'This discount can be used an unlimited number of times';
    } else {
      return ` This discount can only be used ${this.codeMaxUsage} times `;
    }
  }

  getCodeUsage(): string {
    return ` ${this.initialCodeCount} individual codes have been generated for this discount. ${this.usedCodeCount} individual codes have been redeemed for this discount. `;
  }

  getDiscountScopeValue(): string {
    const value = this.discount.buyx_gety ? this.customerGetsComponent.value.discountWhatScope : this.editDiscountForm.get('applyDiscountTypeRadios')?.value;
    switch (value) {
      case 'subtotal':
        return 'Order';
      case 'saleItems':
        return 'Item';
      case 'all': // For buyX getY only
        return 'Item';
      default:
        return '';
    }
  }

  getRequirementType(): string {
    const value = this.editDiscountForm.get('discountUseRequirementsRadios')?.value;
    switch (value) {
      case 'minimumItems':
        return 'Quantity';
      case 'minimumSpend':
        return 'PurchasePrice';
      default:
        return 'None';
    }
  }

  requiresItems(): boolean {
    return ['Quantity'].includes(this.getRequirementType());
  }

  discountsItems(): boolean {
    return ['Item'].includes(this.getDiscountScopeValue());
  }

  /**
   * @returns the total number of codes for an individual discount
   */
  getAdditionalCodes(): number {
    const initialValueNumber = Number(this.editDiscountForm.get('discountIndividualCodeCount')?.value);
    const finalNumber = this.discountService.getTotalCodeCount(this.discount) + initialValueNumber;
    return finalNumber;
  }

  /*
   * Creates an object from form values to API compatible values
   * */
  mapForm() {
    const discountValueAmount = this.discount.buyx_gety ? String(this.customerGetsComponent.value.discountValue) : this.editDiscountForm.get('discountValueAmount').value;
    const formattedDiscountValue = discountValueAmount ? parseFloat(discountValueAmount).toFixed(2) : 0.0;

    const formData: any = {
      id: this.discountID,
      name: this.editDiscountForm.get('discountName')?.value.trim(),
      discount_value: formattedDiscountValue,
      discount_type: this.discount.buyx_gety ? this.customerGetsComponent.form.get('discountWhat')?.value : this.editDiscountForm.get('discountValueType')?.value,
      discount_scope: this.getDiscountScopeValue(),
      discounted_item_variation_ids: this.getDiscountedItems(),
      max_eligible_items: this.getMaxEligibleItems(),
      requirement_type: this.getRequirementType(),
      minimum_sale_total: this.editDiscountForm.get('minimumSpendAmountField')?.value,
      required_items_quantity: this.discount.buyx_gety ? this.customerBuysComponent.value.minimumItemCount : this.editDiscountForm.get('minimumItemsQtyField')?.value,
      required_item_variation_ids: this.getRequiredItems(),
      maximum_discount_value: this.editDiscountForm.get('discountValueMaxOff')?.value,
      starts_at: this.discountFormService.formatDateForSubmission(this.editDiscountForm.get('fromDate')?.value),
      expires_at: this.discountFormService.formatDateForSubmission(this.editDiscountForm.get('toDate')?.value),
      max_use_count:
        this.editDiscountForm.get('discountMaxUseRadios')?.value === 'limited'
          ? this.editDiscountForm.get('discountMaxUseCount')?.value
          : null,
      code_count: this.getAdditionalCodes(),
    };

    return formData;
  }


  getRequiredItems(): number[] {
    if(this.discount.buyx_gety) {
      return this.customerBuysComponent.value.customerBuys.map(Number);
    }
    return this.requiresItems()
      ? this.editDiscountForm.get('saleItemsRequiredSelections')?.value
      : [];
  }

  getDiscountedItems(): number[] {
    if(this.discount.buyx_gety) {
      return this.customerGetsComponent.value.customerGets.map(Number);
    }
    return this.discountsItems()
      ? this.editDiscountForm.get('saleItemsPerOrderSelections')?.value
      : [];
  }

  getMaxEligibleItems(): number | null {
    if(this.discount.buyx_gety) {
      return this.customerGetsComponent.form.get('howManyItems').value;
    }
    const option = this.editDiscountForm.get('saleItemsPerOrderRadios')?.value;
    switch (option) {
      case 'oneItem':
        return 1;
      case 'allItems':
        return 0;

      case 'setAmount':
        return this.editDiscountForm.get('setAmountQtyField').value;
      default:
        return null;
    }
  }

  /*
   * Updates validations of form controls depending on values
   * */
  setupConditionalValidation() {
    // How do you want to apply the discount?
    const saleItemsPerOrderRadios = this.editDiscountForm.get('saleItemsPerOrderRadios');
    const saleItemsPerOrderSelections = this.editDiscountForm.get('saleItemsPerOrderSelections');
    const setAmountQtyField = this.editDiscountForm.get('setAmountQtyField');
    const applyDiscountTypeRadios = this.editDiscountForm.get('applyDiscountTypeRadios');

    applyDiscountTypeRadios.valueChanges.subscribe((value) => {
      if (value === 'saleItems' && !this.discount.buyx_gety) {
        saleItemsPerOrderRadios.setValidators([Validators.required]);
        saleItemsPerOrderSelections.setValidators([Validators.required]);
      } else {
        saleItemsPerOrderRadios.clearValidators();
        saleItemsPerOrderSelections.clearValidators();
      }
      saleItemsPerOrderRadios.updateValueAndValidity();
      saleItemsPerOrderSelections.updateValueAndValidity();
    });

    saleItemsPerOrderRadios.valueChanges.subscribe((value) => {
      if (value === 'setAmount') {
        setAmountQtyField.setValidators([Validators.required, Validators.pattern(/^0*[1-9]\d*$/), Validators.min(1)]);
      } else {
        setAmountQtyField.clearValidators();
      }
      setAmountQtyField.updateValueAndValidity();
    });

    const discountUseRequirementsRadios = this.editDiscountForm.get('discountUseRequirementsRadios');
    const minimumSpendAmountField = this.editDiscountForm.get('minimumSpendAmountField');
    const saleItemsRequiredSelections = this.editDiscountForm.get('saleItemsRequiredSelections');
    const minimumItemsQtyField = this.editDiscountForm.get('minimumItemsQtyField');

    discountUseRequirementsRadios.valueChanges.subscribe((value) => {
      if (value === 'minimumSpend') {
        minimumSpendAmountField.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.pattern(/^\d+(?:\.\d{0,2})?$/),
          Validators.pattern('^[0-9]+(.[0-9]{1,2})?$'),
        ]);
        saleItemsRequiredSelections.clearValidators();
        minimumItemsQtyField.clearValidators();
      } else if (value === 'minimumItems') {
        saleItemsRequiredSelections.setValidators([Validators.required]);
        minimumItemsQtyField.setValidators([
          Validators.required,
          Validators.pattern(/^0*[1-9]\d*$/),
          Validators.min(1),
        ]);
        minimumSpendAmountField.clearValidators();
      } else {
        minimumSpendAmountField.clearValidators();
        saleItemsRequiredSelections.clearValidators();
        minimumItemsQtyField.clearValidators();
      }
      minimumItemsQtyField.updateValueAndValidity();
      saleItemsRequiredSelections.updateValueAndValidity();
      minimumSpendAmountField.updateValueAndValidity();
    });

    const discountMaxUseCount = this.editDiscountForm.get('discountMaxUseCount');
    const discountMaxUseRadios = this.editDiscountForm.get('discountMaxUseRadios');

    discountMaxUseRadios.valueChanges.subscribe((value) => {
      if (value === 'limited') {
        discountMaxUseCount.setValidators([
          Validators.required,
          Validators.pattern(/^0*[1-9]\d*$/),
          Validators.min(this.usedCodeCount || 1),
        ]);
      } else {
        discountMaxUseCount.clearValidators();
        discountMaxUseCount.setValue(null);
      }
      discountMaxUseCount.updateValueAndValidity();
    });

    // Add validator to discountIndividualCodeCount only if the form was submitted with a number
    if (this.editDiscountForm.get('discountIndividualCodeCount')?.value) {
      const discountIndividualCodeCount = this.editDiscountForm.get('discountIndividualCodeCount');
      discountIndividualCodeCount.setValidators([Validators.pattern(/^0*[1-9]\d*$/)]);
      discountIndividualCodeCount.updateValueAndValidity();
    }
  }

  subscribeToFormChanges(): void {
    this.editDiscountForm.get('fromDate').valueChanges.subscribe(this.updateExcludeDates.bind(this));
    this.editDiscountForm.get('toDate').valueChanges.subscribe(this.updateExcludeDates.bind(this));

    this.editDiscountForm
      .get('saleItemsPerOrderSelections')
      .valueChanges.pipe(debounceTime(500), take(1))
      .subscribe((selectedItems: number[]) => this.updateDiscountedItemsTable(selectedItems));

    this.editDiscountForm
      .get('saleItemsRequiredSelections')
      .valueChanges.pipe(debounceTime(500), take(1))
      .subscribe((requiredItems: number[]) => this.updateRequiredItemsTable(requiredItems));
  }

  formatDiscountValueAmount(value: number | string): string {
    if (value == null) {
      return '';
    }
    const num = Number(value);
    return num % 1 === 0 ? num.toString() : num.toFixed(2);
  }

  private setDiscountValueValidators(discountType: string): void {
    const discountValueAmountControl = this.editDiscountForm.get('discountValueAmount');
    let validators = [];
    switch (discountType) {
      case 'free':
        validators = [
          Validators.required,
          Validators.pattern('^[0-9]+(\\.[0-9]{0,2})?$'),
          Validators.max(100),
          Validators.min(0)
        ]
        break
      case 'percentage':
        validators = [
          Validators.required,
          Validators.pattern('^(100|\\d{1,2})(\\.\\d{1,2})?$'),
          Validators.min(1),
          Validators.max(100),
          this.discountFormService.percentageDiscountValidator(),
        ]
        break
      case 'amount':
        validators = [
          Validators.required,
          Validators.pattern('^\\d+(\\.\\d{1,2})?$'),
          Validators.min(1),
          Validators.max(999_999_999.99),
        ]
        break;
    }

    this.setValidators(discountValueAmountControl, validators);
  }
}

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DiscountSummaryService {
  constructor() {}

  // How do you want to apply the discount?
  getDiscountScope(form: FormGroup): string {
    const applyDiscountType = form.get('applyDiscountTypeRadios').value;
    switch (applyDiscountType) {
      case 'subtotal':
        return 'The discount applies to the cart subtotal.';
      case 'saleItems':
        return `${this.getSaleItemsText(form)}`;
      default:
        return '';
    }
  }

  // How do you want to apply the discount card, Discount the sale items you choose option
  getSaleItemsPerOrderRadios(form: FormGroup): string {
    const saleItemsPerOrder = form.get('saleItemsPerOrderRadios').value;
    switch (saleItemsPerOrder) {
      case 'oneItem':
        return ' One (1) lowest price sale item will be discounted in each order';
      case 'allItems':
        return ' All qualifying items will be discounted in each order';
      case 'setAmount':
        const amount = form.get('setAmountQtyField').value;
        return ` ${amount} eligible sale item${amount > 1 ? 's qualify' : ' qualifies'} for the discount`;
      default:
        return '';
    }
  }

  // How do you want to apply the discount? card, Discount the sale items you choose option
  getSaleItemsText(form: FormGroup): string {
    const saleItemsQty = form.get('saleItemsPerOrderSelections').value;
    const itemArray = typeof saleItemsQty === 'string' ? saleItemsQty.split(',') : saleItemsQty;
    const itemCount = Array.isArray(itemArray) ? itemArray.length : 0;

    return ` ${itemCount} sale item${itemCount !== 1 ? 's' : ''} ${
      itemCount === 1 ? 'qualifies' : 'qualify'
    } for the discount.`;
  }

  // What are the requirements to use this discount? card
  getDiscountUseRequirements(form: FormGroup): string {
    const discountUseRequirements = form.get('discountUseRequirementsRadios').value;
    switch (discountUseRequirements) {
      case 'minimumSpend':
        const minSpend = form.get('minimumSpendAmountField').value;
        return ` The discount applies to the cart subtotal. The customer must spend at least $${minSpend} in their order.`;
      case 'minimumItems':
        return ` The discount applies to the cart subtotal. ${this.getminimumItemsQtyField(form)}`;
      case 'noRequirements':
        return '';
      default:
        return ``;
    }
  }

  // What are the requirements to use this discount?
  getDiscountUsage(form: FormGroup): string {
    const requirementType = form.get('discountUseRequirementsRadios').value;
    switch (requirementType) {
      case 'noRequirements':
        return 'an unlimited number of times';
      case 'minimumSpend':
        const minSpend = form.get('minimumSpendAmountField').value;
        return `The customer must spend at least $${minSpend} in their order.`;
      case 'minimumItems':
        return this.getminimumItemsQtyField(form);
      default:
        return 'under specified conditions';
    }
  }

  // What are the requirements to use this discount? card, eligible sale item selection
  getRequiredItemsText(form: FormGroup): string {
    const requiredItems = form.get('saleItemsRequiredSelections').value;
    const itemArray = typeof requiredItems === 'string' ? requiredItems.split(',') : requiredItems;
    const itemCount = Array.isArray(itemArray) ? itemArray.length : 0;

    return ` ${itemCount} sale item${itemCount !== 1 ? 's' : ''}`;
  }

  // What are the requirements to use this discount? card, Buy a minimum number (#) of sale items options
  getminimumItemsQtyField(form: FormGroup): string {
    const minItems = form.get('minimumItemsQtyField').value;
    return `The order must contain at least ${minItems} of the required items.`;
  }

  // When will this discount be available? card
  getDiscountDateRange(form: FormGroup): string {
    const fromDate = form.get('fromDate').value;
    const toDate = form.get('toDate').value;

    if (fromDate && toDate) {
      return `between ${fromDate} - ${toDate}`;
    } else if (fromDate) {
      return `starting ${fromDate}`;
    } else if (toDate) {
      return `until ${toDate}`;
    } else {
      return 'with no specified date range';
    }
  }

  // What is the value of the discount? card
  getFormattedDiscountValue(form: FormGroup): string {
    const discountValueType = form.get('discountValueType').value;
    const discountValueAmount = form.get('discountValueAmount').value;

    if (discountValueType === 'amount') {
      return `$${parseFloat(discountValueAmount).toFixed(2)}`;
    } else if (discountValueType === 'percentage') {
      return `${discountValueAmount}%`;
    }
    return '';
  }
}
